import React, { Fragment } from "react";
import { Link } from "gatsby";
import classnames from 'classnames';
import { domain } from "../../../utils";
import arrow from "../../../images/arrow.svg";

export const BlogItem = ({ blogItems, type }) => {
  return (
    <Fragment>
      {blogItems.length > 0 &&
        blogItems.map(({ slug, title, language, publishing_date, image }, index) => {
          return ((index < 2) || type === 'blog') && (
            <div
              key={publishing_date}
              className="col-12 col-md-6 my-3 my-lg-4"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <Link
                to={`/${language}/blog/${publishing_date.slice(0, 4)}/${slug}`}
                className="card-link"
              >
                <article className={classnames('card-news light-shadow')}>
                  <div className="card-news-img-top pt-4">
                    <img src={`${domain}${image.url}`} alt="blogItem" />
                  </div>
                  <div className="card-news-body">
                    <div className="card-news-title">
                      <div className="small">
                        <small>{publishing_date.slice(0, 10)}</small>
                      </div>
                      <h5 className="text-primary font-weight-bold">{title}</h5>
                    </div>
                  </div>
                  <div className="small read-more">
                    Read more <img alt="arrow" src={arrow} />
                  </div>
                </article>
              </Link>
            </div>
          )
        })}
    </Fragment>
  )
}
