import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import { Blog } from "../components/partials/blog/blog";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";

const BlogPage = ({ data }) => {
  const { language, header, footer } = data.strapi.homepage;
  const { blogitems } = data.strapi;

  const dispatch = useContext(GlobalDispatchContext);
  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language });
  });

  return (
    <Layout header={header} footer={footer}>
      <SEO title="Blog" />
      {blogitems && blogitems.length > 0 && (
        <Blog blogHeading="Blog" type='blog' blogItems={blogitems} />
      )}
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query Blog($id: ID!, $language: STRAPI_JSON!) {
    strapi {
      homepage(id: $id) {
        language
        header {
          title
          logo {
            url
          }
          links {
            label
            slug
          }
        }
        footer {
          title
          copyright
          logo {
            url
          }
          nav {
            heading
            link {
              label
              slug
            }
          }
        }
      }
      blogitems(sort: "publishing_date:desc", where: $language) {
        slug
        language
        title
        publishing_date
        image {
          url
        }
      }
    }
  }
`
