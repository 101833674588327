import React, { Fragment } from "react"
import { BlogItem } from "./blogItem"

export const Blog = ({ blogHeading, blogItems, type }) => {
  return (
    <Fragment>
      <section className="section-home mt-3 position-relative section-cards">
        <div className="container-fluid t-cell-middle">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <div className="title-small border-blue mb-2 mb-md-5">
                <h2 className="text-uppercase">{blogHeading}</h2>
              </div>
              <div className="container-fluid p-0">
                <div className="row">
                  <BlogItem type={type} blogItems={blogItems} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
